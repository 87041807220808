// function editor (str){
//     let reg = new RegExp('/junsheng-admin/','g')
//     str =  str.replace(reg,(result)=>{
//         console.log(result)
//         let begin = str.search(result); // 开始下标
//         let subStr = str.substring(begin,str.length)
//         let url = subStr.substring(0,subStr.search('"'))
//         // http://121.89.216.1:18070/junsheng-admin/upload/b4c0236d-d01b-4148-9c34-3d48b2ee0f59.jpg
//         const img = new Image()
//         img.src ="http://121.89.216.1:18070" + url
//         img.onload = function () { 
//             console.log(document.querySelectorAll(`[src="http://121.89.216.1:18070${url}"]`))
//             for (let index = 0; index < document.querySelectorAll(`[src="http://121.89.216.1:18070${url}"]`).length; index++) {
//                 const element = document.querySelectorAll(`[src="http://121.89.216.1:18070${url}"]`)[index];
//                 element.classList.add("ed")
//                 element.setAttribute("data-editor","xiaoma")


//             }
//          }
//         return 'http://121.89.216.1:18070/junsheng-admin/'
//     })

//     return str
// }

function getTime(str){ // eslint-disable-line
    let date = new Date(str)
        const year =date.getFullYear()
        const month =date.getMonth() + 1
        const day =date.getDate()
        const hour =date.getHours()
        const min = date.getMinutes()
        const sec =date.getSeconds()

        return {
            year,month,day,hour,min,sec
        }
}
function getCookie(name){
    var strcookie = document.cookie;//获取cookie字符串
    var arrcookie = strcookie.split("; ");//分割
    //遍历匹配
    for ( var i = 0; i < arrcookie.length; i++) {
        var arr = arrcookie[i].split("=");
        if (arr[0] == name){
            return arr[1];
        }
    }
    return "";
}
function editor(str){
    this.str = str;
    this.copyStr = str;
    this.searcher = '/junsheng-admin/'
    this.beginStr = 'http://121.89.216.1:18070'
    // this.setStr(this.copyStr)
    return this.r =  this.setStr(this.copyStr) + ""
}
// editor.prototype.setStr = function(str){
//     // debugger // eslint-disable-line
//     let index = str.search(this.searcher)
//     if(index != -1){
//         // 处理传过来的字符串 保证查询的值为第一个
//         let subStr = str.substring(index,str.length)
//         let url = subStr.substring(0,str.search('"')) // 路径
//         console.log(str.search('"'),subStr.length)
//         subStr =subStr.substring(str.search('"'),subStr.length)
//         console.log(subStr)
//         const img = new Image()
//         img.src = this.beginStr + url
//         img.onload = function () { 
//             console.log(document.querySelectorAll(`[src="${url}"]`));
//             for (let index = 0; index < document.querySelectorAll(`[src="${url}"]`).length; index++) {
//                 const element = document.querySelectorAll(`[src="${url}"]`)[index];
//                 console.log(element)
//                 element.src = this.src
//                 element.classList.add("ed")
//                 element.setAttribute("data-editor","xiaoma")
//             }
//         }

//         this.setStr(subStr)
//     }
// }

editor.prototype.setStr = function(){
    let reg = new RegExp('src="/ausbourne-admin/','g')
    return this.str.replace(reg,`data-xiaoma src="http://121.89.216.1:18061/ausbourne-admin/`)
}
const setImg = function(){
        let elements = document.querySelectorAll("[data-xiaoma]")
        console.log(elements)
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index];
            const img = new Image();
            img.src = 'http://121.89.216.1:18061' + element.dataset.url 
            img.onload=function(){
                console.log(elements)
                element.src = this.src
                console.log(element.width)
                if(this.width > 769 && element.width == 0){
                    element.classList.add("ed")
                }
                console.log(this.width)
            }
        }
}


String.prototype.setEditor = function(){
    return new editor(this).r
}
function showText(){
    this.showTextDom = document.createElement("div")
    this.showTextDom.classList.add("shows-text")
    document.body.appendChild(this.showTextDom)
}
showText.prototype.add = function(str){
    let span = document.createElement("span")
    span.innerText = str
    span.classList.add("text")
    this.showTextDom.appendChild(span)
}

export {
    editor,setImg,getTime,showText,getCookie
}
