<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view :key="index" />
</template>
<script>
import { getBrowser } from "./js/getBrowser.js";
import { mapMutations } from "vuex";
import { getCookie } from "./js/uitl.js";

export default {
  data: function () {
    return {
      index: 0,
    };
  },
  methods: {
    ...mapMutations(["setBaseUrl", "setLang"]),
  },
  created() {
    if (document.cookie) {
      if (getCookie("lang") == "en") {
        this.setLang(getCookie("lang"));
      }
    }
  },
  mounted() {
    if (getBrowser() == "trident") {
      console.log("ie浏览器");
    }
    window.addEventListener(
      "hashchange",
      () => {
        var currentPath = window.location.hash.slice(1); // 获取输入的路由
        if (this.$router.path !== currentPath) {
          this.$router.push(currentPath); // 动态跳转
        }
      },
      false
    );
    document.dispatchEvent(new Event("render-event"));
  },
  watch: {
    $route: {
      handler: function () {
        this.index++;
      },
      immediate: true,
    },
    "$store.state.lang": {
      handler: function (val) {
        console.log(val);

        if (val == "cn") {
          // this.setBaseUrl('http://121.89.216.1:18061/ausbourne-admin/')
          this.setBaseUrl("https://www.osborne-cn.com:18061/ausbourne-admin/");   // 生产
          // this.setBaseUrl("http://192.168.98.108:18061/ausbourne-admin/");    // 测试
          // this.setBaseUrl("http://192.168.26.26:18061/ausbourne-admin/");    // 本地

        } else if (val == "en") {
          // http://www.osborne-cn.com:18060/ausbourneen-admin/
          // http://121.89.216.1:18060/ausbourneen-admin/
          // this.setBaseUrl('http://121.89.216.1:18060/ausbourneen-admin/')
          this.setBaseUrl("https://www.osborne-en.com:18060/ausbourneen-admin/");  // 生产
          // this.setBaseUrl("http://192.168.98.108:18060/ausbourneen-admin/");  // 测试
          // this.setBaseUrl("http://192.168.26.26:18060/ausbourneen-admin/");  // 本地
        }
        this.index++;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="less">
@import url("./css/public.css");
@import url("font-awesome");
@import url("animate.css");

.showSwiper {
  animation-name: showSwiper;
}

@keyframes showSwiper {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: -100px;
  }
  100% {
    margin-left: 0px;
  }
}
</style>
