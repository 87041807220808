import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyload from "vue-lazyload";
import "babel-polyfill";
import MetaInfo from 'vue-meta-info'

// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

// import Es6Promise from 'es6-promise'
// require('es6-promise').polyfill()
// Es6Promise.polyfill()

// console.log = function(){
//     return false
// }
console.warn = function () {
  return false;
};

createApp(App)
  .use(store)
  .use(router)
  .use(VueLazyload, {
    // loading:require('./assets/loading.gif')
  })
  .use(MetaInfo)
  .mount("#app");
