import { createStore } from 'vuex'

import cn from '../lang/cn'
import en from '../lang/en'
export default createStore({
  state: {
    // baseUrl:"http://121.89.216.1:18061/ausbourne-admin/",
    baseUrl:"https://www.osborne-cn.com:18061/ausbourne-admin/",  // 生产
    // baseUrl:"http://192.168.26.26:18061/ausbourne-admin/",  // 本地
    // baseUrl:"http://192.168.98.108:18061/ausbourne-admin/",  // 测试

    lang:process.env.VUE_APP_BASE_LANG,
    langs:['cn','en'],
    langPack:{cn,en},
    videoOptions:{
      isActive:false,
      videoUrl:""
    },
    brand:undefined,
    core:null,
    adv:null,
    routerName:"",
  },
  getters: {
  },
  mutations: {
    updateRouterName(state,data){
      state.routerName = data
    },
    setLang(state,key){
      state.lang = key

      // document.cookie = '键=值;expires=失效时间;path=要储存的路径;'
      var date = new Date()
      date.setTime(date.getTime() - 8*3600*1000 + 2*3600*1000)
      document.cookie = `lang=${state.lang};expires=${date};path=/`
      console.log(key)
    },
    setVideo(state,obj){
      // state.videoOptions.isActive = !state.videoOptions.isActive
      console.log(obj)
      if(!obj.videoUrl){
        console.log("111")
        state.videoOptions.isActive = !state.videoOptions.isActive

        // state.videoOptions.isActive = false
        return
      }
      state.videoOptions.videoUrl= obj.videoUrl;
      state.videoOptions.isActive = true
      console.log(state.videoOptions)


    },
    setBrand(state,obj){
      state.brand = obj
    },
    setCore(state,obj){
      state.core = obj
    },
    setAdv(state,obj){
      state.brand = obj
    },
    setBaseUrl(state,url){
      state.baseUrl = url
    }
  },
  actions: {
  },
  modules: {
  }
})
