// import { getCookie } from '@/js/uitl'
import { createRouter , createWebHistory} from 'vue-router'
// import ajax from '@/ajax';
// import HomeView from '../views/HomeView.vue'
const routes = [
  {
    path: '/m',
    name: 'm',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    meta:{
      active_key:"home",
      title:"澳思柏恩",
      titleen:'OSBORNE',
      metaconfig:'web/seoyhService?name=1'
    }
  },
  // 关于我们
  {
    path: '/about/:lc?',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta:{
      active_key:"about",
      title:"关于我们__澳思柏恩",
      titleen:'ABOUT__OSBORNE',
      metaconfig:'web/seoyhService?name=2'
    }
  },
  // 品牌中心
  {
    path: '/brand/:id?',
    name: 'brand',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrandView.vue'),
    meta:{
      active_key:"brand",
      title:"品牌中心__澳思柏恩",
      titleen:'PRODUCT LINES__OSBORNE',
      metaconfig:'web/seoyhService?name=3'
    }
  },
  // 品牌详情页
  {
    path: '/brand/:id/:brand_id',
    name: 'brandDetail',
    meta:{
      active_key:"brand",
      title:"品牌中心__澳思柏恩",
      titleen:'PRODUCT LINES__OSBORNE',
      metaconfig:'web/seoyhxqService?sid=3',
      childmeta:3
    },
    query:{
      id:Number,
      brand_id:Number
    },
    afterEach:() => {
      document.body.scrollTop = 0
      return true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/BrandDetailView.vue')
  },
  // 核心优势
  {
    path: '/advant',
    name: 'advant',
    // query:{
    //   id:Number
    // },
    meta:{
      active_key:"advant",
      title:"核心优势__澳思柏恩",
      titleen:'CORE ADVANTAGES__OSBORNE',
      metaconfig:'web/seoyhService?name=4'

    },
    component: () => import(/* webpackChunkName: "about" */ '../views/advantageView.vue')
  },
   // 智能制造
   {
    path: '/advant/intel/:id?',
    name: 'intel',
    query:{
      id:Number
    },
    meta:{
      active_key:"advant",
      title:"智能制造__澳思柏恩",
      titleen:'INTELLIGENT FACTORIES__OSBORNE',
      metaconfig:'web/seoyhxqService?sid=3',
      childmeta:1
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/IntelligentView.vue')
  },
  // 资源优势
  {
    path: '/advant/reso/:id?',
    name: 'reso',
    // query:{
    //   id:Number
    // },
    meta:{
      active_key:"advant",
      title:"资源优势__澳思柏恩",
      titleen:'INTELLIGENT MANUFACTURING__OSBORNE',
      metaconfig:'web/seoyhxqService?sid=3',
      childmeta:2
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ResourceView.vue')
  },
  // 智能工厂
  {
   path: '/plant/:id?',
   name: 'plant',
   query:{
     id:Number
   },
   meta:{
    active_key:"plant",
    title:"智能工厂__澳思柏恩",
    titleen:'RESOURCE SUPERIORITY__OSBORNE',
    metaconfig:'web/seoyhService?name=5'
  },
   component: () => import(/* webpackChunkName: "about" */ '../views/PlantView.vue')
 },
  //  招商合作
  {
    path: '/coop/:id?',
    name: 'coop',
    query:{
      id:Number
    },
    meta:{
      active_key:"coop",
      title:"招商合作__澳思柏恩",
      titleen:'COOPERATION__OSBORNE',
      metaconfig:'web/seoyhService?name=6'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CooperationView.vue')
  },
  //  媒体中心__澳思柏恩
  {
    path: '/news/:id?',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta:{
      active_key:"news",
      title:"媒体中心__澳思柏恩",
      titleen:'NEWS CENTER__OSBORNE',
      metaconfig:'web/seoyhService?name=7'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue')
  },
  //  视频中心
  {
    path: '/video/:id',
    name: 'video',
    meta:{
      active_key:"video",
      title:"视频中心__澳思柏恩",
      titleen:'NEWS CENTER__OSBORNE',
      metaconfig:'web/seoyhxqService?sid=7'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/VideoEle.vue')
  },
   //  新闻详情
   {
    path: '/news/detail/:id',
    name: 'newsdetail',
    meta:{
      active_key:"newsdetail",
      title:"新闻详情__澳思柏恩",
      titleen:'NEWS DETAIL__OSBORNE',
      metaconfig:'web/seoyhxqService?sid=7'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/newsDetail.vue')
  },
  // //  新闻详情
  // {
  //   path: '/news/detail/list',
  //   name: 'newsdetail',
  //   meta:{
  //     active_key:"newsdetail",
  //     title:"新闻详情__澳思柏恩",
  //     titleen:'NEWS DETAIL__OSBORNE',
  //     metaconfig:'web/seoyhxqService?sid=7'
  //   },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/newsDetail.vue')
  // },
  //  联系我们
  {
    path: '/content',
    name: 'content',
    meta:{
      active_key:"content",
      title:"联系我们__澳思柏恩",
      titleen:'CONTENT__OSBORNE',
      metaconfig:'web/seoyhService?name=9'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentView.vue')
  },
  //  加入我们
  {
    path: '/add/:id?',
    name: 'add',
    meta:{
      active_key:"add",
      title:"加入我们__澳思柏恩",
      titleen:'JOIN IN__OSBORNE',
      metaconfig:'web/seoyhService?name=8'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddView.vue')
  },
  {
    path: '/',
    name: 'home',
    meta:{
      active_key:"home",
      title:"澳思柏恩",
      titleen:'OSBORNE',
      metaconfig:'web/seoyhService?name=1'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView.vue')
  }, 
  {
    path: '/nav',
    name: 'nav',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  }
]

const router = createRouter({ 
  history: createWebHistory(), 
  routes
})
router.beforeEach(async (to) => {
  console.log(11111111111111)
  console.log(to.meta);
  // document.body.scrollTop = 0
  // if(getCookie('lang')=='en'){
  //   if(!to.meta.title){
  //     document.title = "OSBORNE"
  //   }else{
  //     document.title = to.meta.titleen
  //   }
  // }
  // if(!to.meta.title){
  // document.title = "澳思柏恩"
  // }else{
  //   document.title = to.meta.title
  // }
  
  // let baseUrl = 'http://www.osborne-cn.com:18061/ausbourne-admin/'
   //"http://www.osborne-cn.com:18061/ausbourne-admin/" //  生产
   //"http://192.168.98.108:18061/ausbourne-admin/"    // 测试
   //"http://192.168.26.26:18061//ausbourne-admin/"    // 本地
  
  // if(document.cookie){
    // if(getCookie('lang') == 'en'){
      // baseUrl = 'http://www.osborne-cn.com:18060/ausbourneen-admin/'
       // "http://www.osborne-cn.com:18060/ausbourneen-admin/"  // 生产
      //  "http://192.168.98.108:18060/ausbourneen-admin/"  // 测试
       // "http://192.168.26.26:18060//ausbourneen-admin/"   // 本地
    // }
  // }
  //  let seoconfig =  await ajax(baseUrl + to.meta.metaconfig)
  //  console.log(seoconfig);
  //  let titledom = document.querySelector("head").querySelector("meta[name='title']");
    // for (let index = 0; index < seoconfig.length; index++) {
      // const item = seoconfig[index];
      // if(to.meta.childmeta){
      //   if(item.id == to.meta.childmeta){
      //     if(item.title){
      //       document.title = item.title
      //     }else{
      //       document.title = to.meta.title
      //     }
      //     if(titledom){
      //       // document.querySelector("head").querySelector("meta[name='title']").setAttribute("content",item.title)
      //       document.querySelector("head").querySelector("meta[name='keywords']").setAttribute("content",item.keyword)
      //       document.querySelector("head").querySelector("meta[name='description']").setAttribute("content",item.description)
      //     }else{
      //       // <meta name="title" content="${item.title}">

      //       document.querySelector("head").innerHTML +=`
      //       <meta name="keywords" content="${item.keyword}">
      //       <meta name="description" content="${item.description}">
      //       `
      //     }
      //     break;
      //   }
      // }else{
      //   if(item.title){
      //     document.title = item.title
      //   }else{
      //     document.title = to.meta.title
      //   }
      //   if(titledom){
      //     // document.querySelector("head").querySelector("meta[name='title']").setAttribute("content",item.title)
      //     document.querySelector("head").querySelector("meta[name='keywords']").setAttribute("content",item.keyword)
      //     document.querySelector("head").querySelector("meta[name='description']").setAttribute("content",item.description)        }else{
      //     // <meta name="title" content="${item.title}">
      //     document.querySelector("head").innerHTML +=`
      //     <meta name="keywords" content="${item.keyword}">
      //     <meta name="description" content="${item.description}">
      //     `
      //   }
      //   break;
      // }
      
    // }
    
    // const store = useStore()
    // if(store.userRouter.length || to.path == '/' || to.path == '/index'){
    //       next() //如果登录页或首页 或 vuex中有动态路由数据 直接通过
    // }else{
    //     //拿到浏览器缓存中动态路由的数据 重新添加
    //     const data = JSON.parse( localStorage.getItem('userRouter'))
    //     store.userRouter = data     //重新复制给store
    //     data.forEach(e=>{        //循环添加路由
    //     router.addRoute(e) 
    //     }) 
          
    //     next(to.path)  //添加完成后再次进入

    // }
  return true
})

export default router
